<script setup lang="ts">
import { onBeforeMount, onMounted, ref} from "vue";
import {getThumbnail} from "@/helpers/API";
import PlayIcon from '@/public/playericon.png';

  const props = defineProps({
    id: {
      type: String
    },
    accessToken: {
      type: String
    },
    tenantName: {
      type: String
    },
    name: {
      type: String,
      default: ''
    },
    audit: {
      type: Object,
      default: () => {}
    },
    thumbnail: {
      type: Object,
      default: () => {}
    },
    file: {
      type: Object,
      default: () => {}
    },
    status: {
      type: String,
    },
    type: {
      type: String
    },
    usage: {
      type: Object
    }
  });
  const emit = defineEmits(['focusItem']);
  const imgsrc = ref('');
  const imgEl = ref(null);
  function handleFocus(item : typeof props) {
    emit('focusItem', item);
  }
function millisecondsToMinutesAndSeconds(milliseconds: number) {
  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);

  // Extract minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // Format the result
  const result = minutes + ":" + (remainingSeconds > 9 ? remainingSeconds: "0"+remainingSeconds)+ " MIN";
  return result;
}

onBeforeMount(async () => {
    imgsrc.value = await getThumbnail({url: props.thumbnail.url, accessToken: props.accessToken, tenantName: props.tenantName});
  });
  onMounted(() => {
    if(imgEl.value) {
      imgEl.value.src = imgsrc.value;
    }
  })
</script>

<template>
  <li class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <img :src="imgsrc" class="thumbnail" ref="imgEl"/>
      <div class="absolute w-[250px] h-[140px] left-0 top-0 flex items-center justify-center">
        <div class="w-10">
          <img class="w-10" :src="PlayIcon" />
        </div>
      </div>
    </div>
    <div class="text-details">
<!--      <span>-->
<!--        {{new Date(audit.createdAtUtc).toLocaleDateString('de-de')}},-->
<!--      </span>-->
      <span v-if="file.durationInMilliseconds">
        {{millisecondsToMinutesAndSeconds(file.durationInMilliseconds)}}
      </span>
<!--      <span>-->
<!--        {{ usage.views }} Ansichten-->
<!--      </span>-->
    </div>
    <h5 class="list-item-title">{{ name }}</h5>
  </li>
</template>

<style scoped>
.asset-list-item{
  flex: 0 0 250px;
  @apply my-10 h-[236px] cursor-pointer;

  .thumbnail-wrapper{
    @apply relative;
  }
  .thumbnail {
    @apply w-[250px] h-[140px];
  }
  .text-details {
    @apply my-3 text-xs;
  }
  .list-item-title{
    @apply text-xl w-[250px] line-clamp-2	;
  }
}
</style>