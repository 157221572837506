
export async function fetchToken(tenantName: string) {
    try {
       const query =  await fetch(
           `${import.meta.env.VITE_PUBLIC_PROXY_URL}/token/${tenantName}`
           , {
            method: "get"
        });
       const data = await query.json();
       return data;
    }catch (e) {
        console.error(e);

    }
}

export async function getChannelDetails({id, accessToken}: {id: string, accessToken: string}) {
    try {
        const query =  await fetch(`${import.meta.env.VITE_API_URL}/channels/${id}`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " +accessToken,
                "Content-Type": "application/json"
            },
        });
        const data = await query.json();
        return data;
    }catch (e) {
        console.error(e);
    }
}

export async function getChannelMediaList({id, accessToken}: {id: string, accessToken: string}) {
    try {
        const query =  await fetch(`${import.meta.env.VITE_API_URL}/channels/${id}/media?${new URLSearchParams({
            perpage: 50,
            sort: 'createdAtUtc:desc'
        })}`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " +accessToken,
                "Content-Type": "application/json"
            },
        });
        const data = await query.json();
        return data;
    }catch (e) {
        console.error(e);
    }
}
export async function getTenantSettings({accessToken}) {
    try {
        const query = await fetch(`${import.meta.env.VITE_API_URL}/tenants/current/designs`, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " +accessToken,
                "Content-Type": "application/json"}
        });
        const data = await query.json();
        return data;
    }catch (e) {
        console.error(e);
    }
}
export async function getThumbnail({url, accessToken, tenantName} : {url: string, accessToken?: string, tenantName?: string}) {
    if (url === null) {
        return '';
    }
    const headers = new Headers();
    let getToken = accessToken;
    if(!getToken && tenantName) {
        const getNewToken = await fetchToken(tenantName);
        getToken = getNewToken.accessToken;
    }
    headers.set('Authorization', `Bearer ${getToken}`);
    const getImage = await fetch(url, { headers });
    const getBlob = await getImage.blob();
    return URL.createObjectURL(getBlob);
}