<script setup lang="ts">
import ModalListItem from "@/components/ModalListItem.vue";
import {onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import SdPlayerWidget from "@/SdPlayerWidget.ce.vue";

const props = defineProps({
  focusVideo: {
    type: Object
  },
  playerSettings: {
    type: Object
  },
  tenantName: {
    type: String
  },
  mediaList: {
    type: Array
  },
  redirectLink:{
    type: String
  },
  accessToken: {
    type: String
  }
})
const emit = defineEmits(['closeModal']);

const modalContentEl = ref(null);
const focused = ref<typeof props.focusVideo | null>(null);
const channelMedia = ref([]);
const page = ref(0);
const clientWidthModal = ref(0);
const observer = ref<null | ResizeObserver>(null);

onMounted(() => {
  if(props.mediaList && modalContentEl.value) {
    observer.value = new ResizeObserver(() => {
      if(modalContentEl.value){
        const clientWidth = modalContentEl.value?.clientWidth;

        channelMedia.value = paginate(props.mediaList, 3 );
        clientWidthModal.value = clientWidth;
        // if(clientWidth >= 800) {
        //   channelMedia.value = paginate(props.mediaList, 3 );
        //   // useNumberedPage.value = true;
        // }
        // if(clientWidth > 200 && clientWidth < 800) {
        //   channelMedia.value = paginate(props.mediaList, 2 );
        //   // useNumberedPage.value = true;
        // }
      }
    });
    observer.value.observe(modalContentEl.value);
  }

})

onBeforeUnmount(() => {
  if(modalContentEl.value){
    observer.value?.unobserve(modalContentEl.value);
  }
})
onBeforeMount(() => {
  if(props.focusVideo){
    focused.value = props.focusVideo;
  }
})
function paginate (arr: Array<any>, size: number) {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size)
    let page = acc[idx] || (acc[idx] = [])
    page.push(val)

    return acc
  }, [])
}
function handlePage(number : number) {
  if(channelMedia.value){
    if(number < 0){
      page.value = channelMedia.value.length -1; // or atleast last
    }else{
      if(number > channelMedia.value.length -1) {
        page.value = 0;
      }else{
        page.value = number;
      }
    }
  }
}

function handleFocus(video: typeof props.focusVideo) {
  focused.value = video;
}
</script>

<template>
  <div class="modal" v-if="focused" ref="modalContentEl">
    <div class="modal_content" >
      <div class="button-row">
        <button class="w-3 h-3" @click="$emit('closeModal')">
          <font-awesome-icon class="text-sm text-black" size="lg" :icon="['fas', 'xmark']" />
        </button>
      </div>
      <div class="w-[650px] 3xl:w-[800px]">
        <div class="flex justify-center items-center w-[650px] max-h-[365.625px] h-[365.625px] 3xl:h-[450px] 3xl:max-h-[450px] 3xl:w-[800px]  overflow-hidden">
<!--          622,22px-->
          <sd-player-widget :width="clientWidthModal >= 1792 ? '800px' : '650px'" :height="clientWidthModal >= 1792 ? '450px' : '365.625px'" :key="focused.id" :access-token="accessToken"
                            :player-settings="playerSettings" :tenantName="tenantName" :assetId="focused.id"></sd-player-widget>
        </div>
        <h2 class="modal_title">
          {{ focused.name }}
        </h2>
      </div>
      <ul class="asset-list" v-if="channelMedia?.length">
        <modal-list-item @focusItem="handleFocus($event)" v-bind="media" :key="media.id" v-for="media in channelMedia[page]" :access-token="accessToken"/>
      </ul>
      <div class="navigation-row">
        <div>
          <button class="w-5 h-5" @click="handlePage( page -1)">
            <font-awesome-icon class="text-sm text-neutral-200 hover:text-black active:text-black ease-in transition-colors" size="lg" :icon="['fas', 'arrow-left']" />
          </button>
        </div>
        <div class="flex items-center gap-2">
          <button class="w-2 h-2"
                  :class="index === page ? 'bg-black' :'bg-neutral-200'"
                  :key="`button-${index}`" v-for="(_,index) in channelMedia"
                  @click="handlePage(index)"
          >
          </button>
        </div>
        <div>
          <button class="w-5 h-5" @click="handlePage(page + 1)">
            <font-awesome-icon class="text-sm text-neutral-200 hover:text-black active:text-black duration-300 ease-in transition-colors" size="lg" :icon="['fas', 'arrow-right']" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 z-[10000] flex items-center justify-center;
  .modal_content {
    @apply p-6 3xl:p-8 bg-white flex flex-col items-center w-auto;
  }
  .button-row {
    @apply w-full flex justify-end items-center mb-4;
  }
  .modal_title {
    @apply text-xl 3xl:text-3xl mt-4
  }
  .asset-list{
    @apply flex justify-center overflow-hidden gap-6;
  }
  .navigation-row{
    @apply flex justify-between items-center w-full;
  }
}
</style>