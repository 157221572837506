// import { createWebHashHistory, createRouter } from 'vue-router'
import { createI18n } from 'vue-i18n'
// import { createPinia } from 'pinia'
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import {faPlay} from "@fortawesome/pro-solid-svg-icons/faPlay";
import {faXmark} from "@fortawesome/pro-solid-svg-icons/faXmark";
import {library} from "@fortawesome/fontawesome-svg-core";
import VueMatomo from "vue-matomo";
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
// import { defaultRoutes } from '../main.routes'

export const pluginsWrapper = {
    install(GivenVue: any) {
        const Vue = GivenVue

        // //Pinia
        // const pinia = createPinia()
        // pinia.use(piniaPluginPersistedstate)
        // Vue.use(pinia)
        //
        // //Vue Router
        // const router = createRouter({
        //     history: createWebHashHistory(),
        //     routes: defaultRoutes,
        // })
        // Vue.use(router)

        //Vue I18n
        const i18n = createI18n({
            locale: 'en',
            fallbackLocale: 'en',
        })

        library.add(faPlay, faArrowLeft, faArrowRight, faXmark);
        Vue.component('font-awesome-icon', FontAwesomeIcon);
        Vue.use(i18n)
        Vue.use(VueMatomo, {
            requireConsent: false,
            host: "https://log.streamdiver.com",
            // trackerFileName: 'index',
            // trackerScriptUrl: 'https://log.streamdiver.com',
            trackerUrl: "https://log.streamdiver.com/js/tracker.php",
            debug: true,
            enableLinkTracking: true,
            trackInitialView: true,
            disableCookies: false,
            requireCookieConsent: false,
            enableHeartBeatTimer: true,
            heartBeatTimerInterval: 15
            /** Other configuration options **/
        });
    },
}
