import VueMatomo from "vue-matomo";

export const pluginsWrapper = {
    install(GivenVue: any) {
        const Vue = GivenVue

        // //Pinia
        // const pinia = createPinia()
        // pinia.use(piniaPluginPersistedstate)
        // Vue.use(pinia)
        //
        // //Vue Router
        // const router = createRouter({
        //     history: createWebHashHistory(),
        //     routes: defaultRoutes,
        // })
        // Vue.use(router)

        //Vue I18n

        Vue.use(VueMatomo, {
            requireConsent: false,
            host: "https://log.streamdiver.com",
            // trackerFileName: 'index',
            // trackerScriptUrl: 'https://log.streamdiver.com',
            trackerUrl: "https://log.streamdiver.com/js/tracker.php",
            debug: true,
            enableLinkTracking: true,
            trackInitialView: true,
            disableCookies: false,
            requireCookieConsent: false,
            enableHeartBeatTimer: true,
            heartBeatTimerInterval: 15
            /** Other configuration options **/
        });
    },
}
