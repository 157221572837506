<template>
  <div class="sd-player-widget-wrapper" version="0.0.1" :id="assetId" ref="playerdiv">
    <div class="unauthorized" v-if="unauthorized">
      <span v-if="language.split('-')[0] == 'de'">
        UNAUTHORIZED <br> you have no access to this asset
      </span>
      <span v-else>
        UNAUTHORIZED <br> you have no access to this asset
      </span>
    </div>
    <div v-else>
      <video :style="{ 'display': !isImage ? 'block' : 'none', width, height}"
        ref="videoHolder"
        class="video-js"
       playsinline
       :data-matomo-title="assetId"
       :title="assetId"
       :alt="assetId"
      ></video>
      <div v-show="isImage" style="position: relative; display: flex; align-items: center; justify-content: center">
        <img ref="logo" style="position: absolute; right: 1rem; top: 1rem; width: 40px;">
        <img ref="image">
      </div>
    </div>
  </div>
</template>
<script>
import videojs from "video.js";
import "@sd/sd-player-plugin";

import "video.js/dist/video-js.css";
import "@sd/sd-player-plugin/dist/sd-player-plugin.css";
import {toRaw} from "vue";

export default {
  name: "SdPlayerWidget",
  props: {
    options: {
      type: String,
      default: JSON.stringify({ foo: "bar" }),
    },
    playerSettings: {
      type: Object
    },
    tenantName: {
      type: String,
      default: null,
    },
    assetId: {
      type: String,
      default: null,
    },
    channelId: {
      type: String,
      default: null,
    },
    accessToken: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: '640px'
    },
    height: {
      type: String,
      default: '360px'
    }
  },
  data() {
    return {
      player: null,
      asset: null,
      token: null,
      settings: null,
      unauthorized: false,
      language: navigator.language || navigator.userLanguage
    };
  },
  computed: {
    rendition() {
      if (this.asset && this.asset.type === 'audio'){
        return this.asset.renditions.filter(r => r.mimeType === 'video/mpeg')[0].source;
      } else {
        if(this.asset){
          return this.asset.renditions.filter(r =>  r.type === 'videostream')[0].source;
        }
        return '';
      }
    },
    isImage() {
      return (this.asset && this.asset.type === 'image')
    }
  },
  methods: {
    handleAndParseErrors(response) {
      if(response.status === 401) {
        this.unauthorized = true;
        throw Error(response.statusText);
      }
      if (!response.ok) {
        console.log(response);
        throw Error(response.statusText);
      }
      return response.json();
    },
    fetchToken(){
      fetch(import.meta.env.VITE_PUBLIC_PROXY_URL + '/token/' +  this.tenantName, {
        method: "get"
        })
        .then(resp => this.handleAndParseErrors(resp))
        .then(resp => {
          this.token = resp.accessToken
          this.fetchAsset()
        })
    },
    fetchAsset() {
      let assetUrl = `${import.meta.env.VITE_API_URL}/media/${this.assetId}`;

      fetch(assetUrl, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " +this.token,
          "Content-Type": "application/json"
        },
      })
        .then(resp => this.handleAndParseErrors(resp))
        .then((response) => {
          this.asset = response.data;
          if(this.playerSettings) {
            this.settings = this.playerSettings;
            if(!this.isImage){
              if(!this.player){
                this.initPlayer();
              }
            } else {
              this.setImageOptions();
            }
          }else{
            this.fetchPlayerSettings()
          }
          if(this.isImage){
            this.fetchWithAuthentication(this.asset.renditions[0].source)
          }
        });
    },
    fetchTenantSettings() {
      let settingsUrl = `${import.meta.env.VITE_API_URL}/tenants/current/settings`;
      if(this.channelId){
        settingsUrl = `${import.meta.env.VITE_API_URL}/channels/${this.channelId}`;
      }

      fetch(settingsUrl, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " +this.token,
          "Content-Type": "application/json"
        },
      })
          .then(resp => this.handleAndParseErrors(resp))
          .then((response) => {
            this.settings = response.data;
            if(window){
              window._paq?.push(['setSiteId', response.data?.matomoSiteId]);
              window._paq?.push(['MediaAnalytics::setPingInterval', 10]);
              window._paq?.push(['MediaAnalytics::scanForMedia', this.$refs.playerdiv]);
            }
            if(!this.isImage){
              if(!this.player){
                this.initPlayer();
              }
            } else {
              this.setImageOptions();
            }
          })
    },
    fetchPlayerSettings() {
      let settingsUrl = `${import.meta.env.VITE_API_URL}/tenants/current/designs`;
      if(this.channelId){
        settingsUrl = `${import.meta.env.VITE_API_URL}/channels/${this.channelId}`;
      }

      fetch(settingsUrl, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " +this.token,
          "Content-Type": "application/json"
        },
      })
        .then(resp => this.handleAndParseErrors(resp))
        .then((response) => {
          this.settings = response.data;
          if(window){
            window._paq?.push(['setSiteId', response.data?.matomoSiteId]);
            window._paq?.push(['MediaAnalytics::setPingInterval', 10]);
            window._paq?.push(['MediaAnalytics::scanForMedia', this.$refs.playerdiv]);
          }
          if(!this.isImage){
            if(!this.player){
              this.initPlayer();
            }
          } else {
            this.setImageOptions();
          }
        })
    },
    fetchWithAuthentication(url) {
      if (url === null) {
        return '';
      }
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${this.token}`);
      fetch(url, { headers })
        .then((response) => {
          if (!response.ok){
            throw Error(response.statusText);
          }
          return response.blob();
        })
        .then((imageBlob) => {
          // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          const imgEl = this.$refs.image;
          imgEl.src = imageObjectURL;
          imgEl.onload = () => URL.revokeObjectURL(imageObjectURL);
        })
        .catch((err) => {
          console.error('💥Thumbnails', err);
        });
    },
    fetchOverlayPlayerLogo() {
      console.log(this.settings, 'settings')
      fetch(this.settings.playerSettings.overlayPlayerLogoUrl, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " +this.token,
          "Content-Type": "application/json"
        },
      })
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          this.$refs.logo.src = objectUrl
          this.$refs.logo.onload = () => {URL.revokeObjectURL(objectUrl)}
        })
    },
    setImageOptions() {
      console.log(this.settings)
      if(this.settings.playerDesign.showOverlayPlayerLogo && this.settings.playerDesign.overlayPlayerLogoUrl){
        this.fetchOverlayPlayerLogo()
      }
    },
    buildOptions() {
      return {
        controlBar: {
          volumePanel: {
            inline: false,
          },
        },
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: true,
          },
        },
        sources: [
          {
            src: this.rendition,
            type: "application/x-mpegurl",
          },
        ],
      };
    },
    initPlayer() {
      const playerOptions = {
        fluid: true,
        autoplay: false,
        controls: true,
        preload: "auto",
        controlBar: {
          volumePanel: { inline: false }
        },
        sources: [
          {
            src: this.rendition,
            type: "application/x-mpegurl",
          },
        ],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: false,
            bandwidth: 3260000,
            limitRenditionByPlayerDimensions: false,
            useDevicePixelRatio: true,
          },
          hls: {
            overrideNative: false,
            bandwidth: 3260000,
            limitRenditionByPlayerDimensions: false,
            useDevicePixelRatio: true,
          },
        },
      }

      const pluginOptions = {
        playerSettings: toRaw(this.settings.playerDesign),
        widgetId: this.assetId,
        token: 'Bearer ' + this.token
      };

      videojs.hookOnce("setup", (player) => {
        const sdPlugin = (window.sdPlugin = player.sdPlugin(pluginOptions));
      });
      this.player = videojs(this.$refs.videoHolder, playerOptions);
    },
  },
  mounted() {
    if(this.accessToken) {
      this.token = this.accessToken;
      if(this.playerSettings){
        window._paq.push(['MediaAnalytics::setPingInterval', 10]);
        window._paq.push(['MediaAnalytics::scanForMedia', this.$refs.playerdiv]);
      }
      this.fetchAsset();
    }else{
      this.fetchToken();
    }
  },
  unmounted() {
    if(this.player){
      this.player.dispose();
    }
  },
};
</script>
<style>
@import "video.js/dist/video-js.min.css";
</style>
<style>
@import "@sd/sd-player-plugin/dist/sd-player-plugin.css";

.sd-player-widget-wrapper{
  @apply w-full h-full;
}
.video-js .vjs-tech {
  width: 100% !important;
  height: 100% !important;
}
.unauthorized {
  @apply text-neutral-50 bg-neutral-500 max-w-[300px] flex items-center justify-center p-2 self-center rounded-lg aspect-video;
}
</style>
