<script setup lang="ts">
import { onBeforeMount, onMounted, ref} from "vue";
import {getThumbnail} from "@/helpers/API";
import PlayIcon from '@/public/playericon.png';

const props = defineProps({
  id: {
    type: String
  },
  accessToken: {
    type: String
  },
  name: {
    type: String,
    default: ''
  },
  audit: {
    type: Object,
    default: () => {}
  },
  thumbnail: {
    type: Object,
    default: () => {}
  },
  file: {
    type: Object,
    default: () => {}
  },
  status: {
    type: String,
  },
  type: {
    type: String
  },
  usage: {
    type: Object
  }
});
const emit = defineEmits(['focusItem']);
const imgsrc = ref('');
const imgEl = ref(null);
function handleFocus(item : typeof props) {
  emit('focusItem', item);
}
function millisecondsToMinutesAndSeconds(milliseconds: number) {
  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);

  // Extract minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format the result
  const result = minutes + ":" + (remainingSeconds > 9 ? remainingSeconds: "0"+remainingSeconds)+ " MIN";
  return result;
}

onBeforeMount(async () => {
  imgsrc.value = await getThumbnail({url: props.thumbnail.url, accessToken: props.accessToken});
});
</script>

<template>
  <li class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <div class="relative items-center flex justify-center">
        <div class="w-10 absolute">
          <img class="w-10" :src="PlayIcon" />
        </div>
        <img :src="imgsrc" class="thumbnail" ref="imgEl"/>
      </div>
<!--      <div class="absolute w-full h-full left-0 top-0 flex items-center justify-center">-->

<!--      </div>-->
    </div>
    <div class="text-content">
      <div class="text-details">
<!--      <span>-->
<!--        {{new Date(audit.createdAtUtc).toLocaleDateString('de-de')}},-->
<!--      </span>-->
        <span v-if="file.durationInMilliseconds">
        {{millisecondsToMinutesAndSeconds(file.durationInMilliseconds)}}
      </span>
<!--        <span>-->
<!--        {{ usage.views }} Ansichten-->
<!--      </span>-->
      </div>
      <h5 class="list-item-title">{{ name }}</h5>
    </div>
  </li>
</template>

<style scoped>
.asset-list-item{
  @apply py-4 px-4 border-b cursor-pointer flex w-full gap-4 box-border;

  .thumbnail-wrapper{
    flex: 0 1 250px;
    @apply relative h-auto flex items-center justify-center;
  }
  .thumbnail {
    @apply h-auto;
  }
  .text-details {
    @apply mb-1 text-xs flex-shrink font-semibold;
  }
  .text-content {
    flex: 1 0 40%;
    @apply overflow-hidden py-2;
  }
  .list-item-title{
    @apply text-base line-clamp-3	break-words;
  }
}
</style>