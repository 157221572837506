import SdPlayerWidget from "./SdPlayerWidget.ce.vue";
import SdCarouselWidget from "@/SdCarouselWidget.ce.vue"

// ?inline can not handle import url() in css therefore fonts are not loaded, workaround is to add font css to the App.vue
import style from './style.css?inline'
import { pluginsWrapper as playerPluginWrapper} from './plugins/playerwidget';
import { pluginsWrapper } from './plugins'

import {defineCustomElement as VueDefineCustomElement, h, createApp, getCurrentInstance} from 'vue'
import { createWebComponent } from 'vue-web-component-wrapper'

createWebComponent({
    rootComponent: SdPlayerWidget,
    elementName: 'sd-player-widget',
    plugins: playerPluginWrapper,
    cssFrameworkStyles: style,
    VueDefineCustomElement,
    h,
    createApp,
    getCurrentInstance,
})

createWebComponent({
    rootComponent: SdCarouselWidget,
    elementName: 'sd-carousel-widget',
    plugins: pluginsWrapper,
    cssFrameworkStyles: style,
    VueDefineCustomElement,
    h,
    createApp,
    getCurrentInstance,
});
